import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import GoldenHourWall from "../../../images/GalleryImages/FigurativeAbstractImages/GoldenHourOne.jpg";
import GoldenHourZoom from "../../../images/GalleryImages/FigurativeAbstractImages/GoldenHourTwo.jpg";
import { goldenHourData } from "./FigurativeArtInfo";

class ArtGoldenHour extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{goldenHourData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{goldenHourData.info}</p>
                <p>{goldenHourData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={GoldenHourWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={GoldenHourZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtGoldenHour;
