import React, { Component } from "react";
import { Link } from "react-router-dom";

class FigurativeAbstractMainHeader extends Component {
  state = {};
  render() {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <div className="desktopHeaderContainer">
        <div className="mainHeader">
          <Link to="/">
            <button
              className="galleryButton"
              onClick={() => {
                scrollToTop();
              }}
            >
              HOME
            </button>
          </Link>
          <Link to="/abstractworks">
            <button
              className="galleryButton"
              onClick={() => {
                scrollToTop();
              }}
            >
              ABSTRACT ART
            </button>
          </Link>

          <Link to="/contact">
            <button
              className="galleryButton"
              onClick={() => {
                scrollToTop();
              }}
            >
              CONTACT
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default FigurativeAbstractMainHeader;
