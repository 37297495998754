import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import OverlyKeenWall from "../../../images/GalleryImages/AbstractImages/OverlyKeenOne.jpg";
import OverlyKeenZoom from "../../../images/GalleryImages/AbstractImages/OverlyKeenTwo.JPG";
import { overlyKeenData } from "./AbstractArtInfo";

class ArtOverlyKeen extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{overlyKeenData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{overlyKeenData.info}</p>
                <p>
                  <i>Part of my Awkward Moments series</i>
                </p>
                <p>{overlyKeenData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={OverlyKeenWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={OverlyKeenZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtOverlyKeen;
