import React, { Component } from "react";
import AboutSamPagePhotoOne from "../../images/aboutSam.JPG";
import AboutSamPagePhotoTwo from "../../images/OtherImages/AboutSamBackgroundOne.JPG";
import AboutSamHeader from "./AboutSamHeader";

class AboutSamPage extends Component {
  render() {
    return (
      <div>
        <AboutSamHeader />
        <div className="aboutSamTextAndPhotoDivOne">
          <div className="AboutSamPagePhotoOneContainer">
            <img
              src={AboutSamPagePhotoOne}
              alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
            />
          </div>
          <div className="aboutSamTextDivOne">
            <p>
              Having grown up in Thailand, Texas, Holland and the UK, Sam has
              been exposed to a wide range of cultures; broadening his mind and
              expanding his need to peek beyond the surface.
            </p>
            <p>
              In his younger life he was fascinated by the insecurities and
              idiosyncrasies of people and loves weaving these ideas and themes
              into his work.
            </p>
            <p>
              He is self-trained and eager not to take his inspirations from
              other artists, but from the world around him.
            </p>
            <p>
              Alongside art he has also worked as an actor on stage, screen and
              as a vocalist.
            </p>
            <p>
              His passion for exciting and entertaining comes across in his use
              of bold textures and rich colour.
            </p>
          </div>
        </div>
        <div className="aboutSamTextAndPhotoDivTwo">
          <div className="AboutSamPagePhotoTwoContainer">
            <img
              src={AboutSamPagePhotoTwo}
              alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
            />
          </div>
          <div className="aboutSamTextDivTwo">
            <p>
              As a fairly young artist Sam’s technique and style is evolving
              rapidly, he has always battled with his over-active mind; getting
              lost in the detail of every new thing he sees.
            </p>
            <p>He finds his inspirations wherever he looks.</p>
            <p>
              Like most artists his need to express comes both from the world
              around him and from within, he is an empathetic performer and
              artist, obsessing over the need to tell stories with his work.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutSamPage;
