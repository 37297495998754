import React, { Component } from "react";
class AbstractWorksHeader extends Component {
  render() {
    return (
      <div>
        <div className="galleryTitleBox">
          <h2 className="galleryTitle">Abstract Works</h2>
        </div>
      </div>
    );
  }
}

export default AbstractWorksHeader;
