import React, { Component } from "react";
class FigurativeAbstractWorksHeader extends Component {
  render() {
    return (
      <div>
        <div className="galleryTitleBox">
          <h2 className="galleryTitle">Figurative Abstract Works</h2>
        </div>
      </div>
    );
  }
}

export default FigurativeAbstractWorksHeader;
