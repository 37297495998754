import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import PollyWall from "../../../images/GalleryImages/AbstractImages/PollyOne.jpg";
import PollyZoom from "../../../images/GalleryImages/AbstractImages/PollyTwo.jpg";
import { pollyData } from "./AbstractArtInfo";

class ArtPolly extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{pollyData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{pollyData.info}</p>
                <p>{pollyData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={PollyWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={PollyZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtPolly;
