import React, { Component } from "react";
import { Link } from "react-router-dom";
import SocialMediaLinks from "./SocialMediaLinks";
import MainLogo from "../../logos/sb-name.png";

class Header extends Component {
  state = {};
  render() {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <div className="desktopHeaderContainer">
        <div className="mainHeader">
          <SocialMediaLinks />
          <div className="headerMainLogoContainer">
            <Link to="/">
              <img
                className="headerMainLogo"
                onClick={() => {
                  scrollToTop();
                }}
                src={MainLogo}
                alt="SamBarrettArt Abstract Art Artist London"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
