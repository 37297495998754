import React, { Component } from "react";
import HomePage from "./components/homePageComponents/HomePage";
import Header from "./components/headerComponents/Header";
import FigurativeAbstractWorksPage from "./components/galleryComponents/FigurativeAbstractWorksPage";

import AbstractWorksPage from "./components/galleryComponents/AbstractWorksPage";
import AbstractMainHeader from "./components/headerComponents/AbstractMainHeader";
import FigurativeAbstractMainHeader from "./components/headerComponents/FigurativeAbstractMainHeader";
import ContactMainHeader from "./components/headerComponents/ContactMainHeader";

import AboutSamPage from "./components/aboutSamPageComponents/AboutSamPage";
import ContactPage from "./components/contactPageComponents/ContactPage";

import LoadingLogo from "./logos/sb-final1.png";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class App extends Component {
  state = { loading: true };

  componentDidMount() {
    // this simulates an async action, after which the component will render the content
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <div id="app" className="loader">
          <img
            src={LoadingLogo}
            alt="SamBarrettArt Abstract Art Artist London"
          />
        </div>
      );
    }
    return (
      <BrowserRouter>
        <div>
          <Route exact path="/" component={Header} />
          <Route exact path="/abstractworks" component={AbstractMainHeader} />
          <Route
            exact
            path="/figurativeabstractworks"
            component={FigurativeAbstractMainHeader}
          />
          <Route exact path="/about" component={ContactMainHeader} />
          <Route exact path="/contact" component={ContactMainHeader} />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/abstractworks" component={AbstractWorksPage} />
            <Route
              exact
              path="/figurativeabstractworks"
              component={FigurativeAbstractWorksPage}
            />
            <Route exact path="/about" component={AboutSamPage} />
            <Route exact path="/contact" component={ContactPage} />

            <Redirect to="/" />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2000));
}

export default App;
