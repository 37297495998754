import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import HeWall from "../../../images/GalleryImages/AbstractImages/HeOne.jpg";
import HeZoom from "../../../images/GalleryImages/AbstractImages/HeTwo.JPG";
import { heData } from "./AbstractArtInfo";

class ArtHe extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{heData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{heData.info}</p>
                <p>{heData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={HeWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={HeZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtHe;
