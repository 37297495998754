export const braveFaceData = {
  title: "Brave Face",
  info: "45x60 cm acrylic on canvas",
  price: "SOLD",
};

export const sheData = {
  title: "She",
  info: "70x100 cm acrylic and spray paint on canvas",
  price: "- £950 -",
};

export const fakeNewsData = {
  title: "Fake News",
  info: "45x60 cm acrylic on canvas",
  price: "- £575 -",
};

export const heData = {
  title: "He",
  info: "70x100 cm acrylic and spray paint on canvas",
  price: "- £950 -",
};

export const paradiseData = {
  title: "Paradise",
  info: "65x65 cm acrylic and resin on canvas",
  price: "- £800 -",
};

export const scatteredData = {
  title: "Scattered",
  info: "45x60 cm acrylic on canvas",
  price: "- £575 -",
};

export const tooCloseData = {
  title: "Too Close",
  info: "90x90 cm acrylic and spray paint on canvas",
  price: "SOLD - Prints Available",
};

export const glimpseOfGloryData = {
  title: "Glimpse Of Glory",
  info: "120x120cm acrylic and spray paint on canvas",
  price: "SOLD",
};

export const innerTurmoilData = {
  title: "Inner Turmoil",
  info: "120x120cm acrylic and spray paint on canvas",
  price: "- £1850 -",
};

export const wavesData = {
  title: "Waves",
  info: "70x100 cm acrylic on canvas",
  price: "- £950 -",
};

export const shatteredData = {
  title: "Shattered",
  info: "50x50 cm acrylic and spray paint on canvas",
  price: "- £500 -",
};

export const overlyKeenData = {
  title: "Overly Keen",
  info: "45x60 cm acrylic on canvas",
  price: "- £575 -",
};

export const loudData = {
  title: "Loud",
  info: "45x60 cm acrylic on canvas",
  price: "- £575 -",
};

export const pollyData = {
  title: "Polly",
  info: "51x76 cm acrylic on canvas",
  price: "- £725 -",
};

export const bartData = {
  title: "Bart",
  info: "51x76 cm acrylic on canvas",
  price: "- £725 -",
};
