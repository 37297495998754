import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import FakeNewsWall from "../../../images/GalleryImages/AbstractImages/FakeNewsOne.jpg";
import FakeNewsZoom from "../../../images/GalleryImages/AbstractImages/FakeNewsTwo.jpg";
import { fakeNewsData } from "./AbstractArtInfo";

class ArtFakeNews extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{fakeNewsData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{fakeNewsData.info}</p>
                <p>
                  <i>
                    Previously exhibited at the Royal Cambrian Academy of Art
                  </i>
                </p>
                <p>{fakeNewsData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={FakeNewsWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={FakeNewsZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtFakeNews;
