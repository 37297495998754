import React, { Component } from "react";
class ContactPageHeader extends Component {
  render() {
    return (
      <div>
        <div className="galleryTitleBox">
          <h2 className="galleryTitle">Contact</h2>
        </div>
      </div>
    );
  }
}

export default ContactPageHeader;
