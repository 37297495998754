import React, { Component } from "react";
import ArtBraveFace from "./abstractGalleryComponents/ArtBraveFace";
import ArtShe from "./abstractGalleryComponents/ArtShe";
import ArtFakeNews from "./abstractGalleryComponents/ArtFakeNews";
import ArtHe from "./abstractGalleryComponents/ArtHe";
import ArtParadise from "./abstractGalleryComponents/ArtParadise";
import ArtScattered from "./abstractGalleryComponents/ArtScattered";
import ArtTooClose from "./abstractGalleryComponents/ArtTooClose";
import ArtWaves from "./abstractGalleryComponents/ArtWaves";
import ArtShattered from "./abstractGalleryComponents/ArtShattered";
import AbstractWorksHeader from "./AbstractWorksHeader";
import ArtGlimpseOfGlory from "./abstractGalleryComponents/ArtGlimpseOfGlory";
import ArtInnerTurmoil from "./abstractGalleryComponents/ArtInnerTurmoil";
import ArtOverlyKeen from "./abstractGalleryComponents/ArtOverlyKeen";
import ArtLoud from "./abstractGalleryComponents/ArtLoud";
import ArtPolly from "./abstractGalleryComponents/ArtPolly";
import ArtBart from "./abstractGalleryComponents/ArtBart";

class AbstractWorksPage extends Component {
  render() {
    return (
      <div>
        <AbstractWorksHeader />
        <div className="infoPageSubHeadingAbs">
          {/* <a href="https://pngtree.com/so/black">black png from pngtree.com/</a> */}
          <p className="infoPageSubHeadingAbsText">
            Sam takes great joy in observing and recreating inner emotions and
            feelings through his abstract works. His particular interests are
            gender stereotypes and mental health.
          </p>
        </div>

        <ArtShe />
        <ArtHe />
        <ArtTooClose />
        <ArtGlimpseOfGlory />
        <ArtInnerTurmoil />
        <ArtShattered />
        <ArtFakeNews />
        <ArtBraveFace />
        <ArtScattered />
        <ArtOverlyKeen />
        <ArtLoud />
        <ArtWaves />
        <ArtParadise />
        <ArtPolly />
        <ArtBart />
        <p className="copyrightText">Copyright © Sam Barrett 2021</p>
      </div>
    );
  }
}

export default AbstractWorksPage;
