import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import ParadiseWall from "../../../images/GalleryImages/AbstractImages/ParadiseOne.jpg";
import ParadiseZoom from "../../../images/GalleryImages/AbstractImages/ParadiseTwo.jpg";
import { paradiseData } from "./AbstractArtInfo";

class ArtParadise extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{paradiseData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{paradiseData.info}</p>
                <p>{paradiseData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={ParadiseWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={ParadiseZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtParadise;
