import React from "react";
import ArtHome from "./figurativeGalleryComponents/ArtHome";
import ArtBelowTheLine from "./figurativeGalleryComponents/ArtBelowTheLine";
import ArtPolluted from "./figurativeGalleryComponents/ArtPolluted";
import ArtBangkok from "./figurativeGalleryComponents/ArtBangkok";
import ArtEveryDayCircus from "./figurativeGalleryComponents/ArtEveryDayCircus";
import ArtReach from "./figurativeGalleryComponents/ArtReach";
import ArtGoldenHour from "./figurativeGalleryComponents/ArtGoldenHour";
import ArtMine from "./figurativeGalleryComponents/ArtMine";
import FigurativeAbstractWorksHeader from "./FigurativeAbstractWorksHeader";
import ArtEarlyRiser from "./figurativeGalleryComponents/ArtEarlyRiser";
import ArtDysmorphia from "./figurativeGalleryComponents/ArtDysmorphia";
class FigurativeAbstractWorksPage extends React.Component {
  render() {
    return (
      <div>
        <FigurativeAbstractWorksHeader />
        <div className="infoPageSubHeadingTitle">
          <h1>City Scapes</h1>
        </div>
        <div className="infoPageSubHeadingCity">
          {/* <a href="https://pngtree.com/so/black">black png from pngtree.com/</a> */}
          <p className="infoPageSubHeadingCityText">
            As a London based artist sam is constantly surrounded by an array of
            incredible buildings. He is particularly interested by the way light
            reflects and effects shape, whilst also finding beauty in the light
            pollution that surrounds us.
          </p>
        </div>

        <ArtHome />
        <ArtBelowTheLine />
        <ArtPolluted />
        <ArtBangkok />
        <ArtEveryDayCircus />
        <ArtReach />
        <ArtEarlyRiser />
        <ArtGoldenHour />
        <ArtMine />
        <ArtDysmorphia />
        <p className="copyrightText">Copyright © Sam Barrett 2021</p>
      </div>
    );
  }
}

export default FigurativeAbstractWorksPage;
