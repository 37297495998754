export const homeData = {
  title: "Home",
  info: "120x80 cm acrylic and resin on canvas",
  price: "- £1625 -",
};

export const belowTheLineData = {
  title: "Below The Line",
  info: "50x50 cm acrylic on canvas",
  price: "- £425 -",
};

export const pollutedData = {
  title: "Polluted",
  info: "65x65 cm acrylic and spray paint on canvas",
  price: "SOLD",
};

export const bangkokData = {
  title: "Bangkok",
  info: "75x75 cm Acrylic and Spray paint on canvas",
  price: "- £800 -",
};

export const everydayCircusData = {
  title: "Everyday Circus",
  info: "40x80 cm acrylic and spray paint on canvas",
  price: "SOLD",
};

export const reachData = {
  title: "Reach",
  info: "170x70 cm acrylic and spray paint on canvas",
  price: "SOLD",
};

export const goldenHourData = {
  title: "Golden Hour",
  info: "50x50 cm acrylic and spray paint on canvas",
  price: "- £625 -",
};
export const mineData = {
  title: "Mine",
  info: "50x50 cm acrylic on canvas",
  price: "SOLD",
};

export const earlyRiserData = {
  title: "Early Riser",
  info: "170x70 cm acrylic and spray paint on canvas",
  price: "- £2250 -",
};

export const dysmorphiaData = {
  title: "Dysmorphia",
  info: "65x80 cm acrylic on canvas",
  price: "- £850 -",
};
