import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import BartWall from "../../../images/GalleryImages/AbstractImages/BartOne.jpg";
import BartZoom from "../../../images/GalleryImages/AbstractImages/BartTwo.jpg";
import { bartData } from "./AbstractArtInfo";

class ArtBart extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{bartData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{bartData.info}</p>
                <p>{bartData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={BartWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={BartZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtBart;
