import React from "react";
import { useForm, ValidationError } from "@formspree/react";
function ContactForm() {
  const [state, handleSubmit] = useForm("meqnaqlv");
  if (state.succeeded) {
    return (
      <div className="contactFormSuccessDiv">
        <p>Thanks for reaching out!</p>
        <p>Sam will be in contact within 24 hours.</p>
      </div>
    );
  }
  return (
    <form className="contactForm" onSubmit={handleSubmit}>
      <div className="formInputOverallContainer">
        <div className="nameInputContainer">
          <div className="formInputContainer">
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" type="text" name="firstName" />
          </div>
          <div className="formInputContainer">
            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" type="text" name="lastName" />
          </div>
        </div>

        <div className="formInputContainer">
          <label htmlFor="email">Email Address</label>
          <input id="email" type="email" name="email" />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <div className="formInputContainer">
          <label htmlFor="message">Request</label>
          <textarea id="message" name="message" />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>
      </div>
      <button
        className="formSubmitButton"
        type="submit"
        disabled={state.submitting}
      >
        Submit
      </button>
    </form>
  );
}
function App() {
  return <ContactForm />;
}
export default App;
