import React, { Component } from "react";
import ContactForm from "../contactPageComponents/ContactForm";
import ContactPageHeader from "./ContactPageHeader";
class ContactPage extends Component {
  render() {
    return (
      <div>
        <ContactPageHeader />
        <div className="contactPage">
          <h1>Interested?</h1>
          <p>Please get in touch using the form below.</p>
          <ContactForm />
        </div>
      </div>
    );
  }
}

export default ContactPage;
