import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import HomeWall from "../../../images/GalleryImages/FigurativeAbstractImages/HomeOne.jpg";
import HomeZoom from "../../../images/GalleryImages/FigurativeAbstractImages/HomeTwo.jpg";
import { homeData } from "./FigurativeArtInfo";

class ArtHome extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{homeData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{homeData.info}</p>
                <p>{homeData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={HomeWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={HomeZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtHome;
