import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import EarlyRiserWall from "../../../images/GalleryImages/FigurativeAbstractImages/EarlyRiserOne.jpg";
import EarlyRiserZoom from "../../../images/GalleryImages/FigurativeAbstractImages/EarlyRiserTwo.jpg";
import { earlyRiserData } from "./FigurativeArtInfo";

class ArtEarlyRiser extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="artAndTextContainer">
            <div className="artTextOnlyContainer">
              <div className="artTitleText">
                <h3>{earlyRiserData.title}</h3>
              </div>
              <div className="artInfoText">
                <p>{earlyRiserData.info}</p>
                <p>{earlyRiserData.price}</p>
              </div>
            </div>
            <div className="artSliderContainer">
              <Carousel fade variant="dark" indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={EarlyRiserWall}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={EarlyRiserZoom}
                    alt="Abstract Art Artist London Sam Barrett SamBarrett SamBarrettArt"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtEarlyRiser;
