import React, { Component } from "react";

import HomeTitle from "./HomeTitle";
import { Link } from "react-router-dom";

class HomePage extends Component {
  state = {};
  render() {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    return (
      <div>
        <HomeTitle />
        <div className="homePageBoxContainer">
          <div className="homePageBoxContainerTop">
            <Link
              to="/abstractworks"
              onClick={() => {
                scrollToTop();
              }}
            >
              <div className="homePageBox homePageBoxAbstract">
                <h2 className="homePageBoxTitle">Abstract Works</h2>
              </div>
            </Link>

            <Link
              to="/figurativeabstractworks"
              onClick={() => {
                scrollToTop();
              }}
            >
              <div className="homePageBox homePageBoxFigurative">
                <h2 className="homePageBoxTitle">Figurative Abstract Works</h2>
              </div>
            </Link>
          </div>
          <div className="homePageBoxContainerBottom">
            <Link
              to="/about"
              onClick={() => {
                scrollToTop();
              }}
            >
              <div className="homePageBox homePageBoxAbout">
                <h2 className="homePageBoxTitle">About Sam</h2>
              </div>
            </Link>
            <Link
              to="/contact"
              onClick={() => {
                scrollToTop();
              }}
            >
              <div className="homePageBox homePageBoxContact">
                <h2 className="homePageBoxTitle">Contact</h2>
              </div>
            </Link>
          </div>
          <p className="copyrightText">Copyright © Sam Barrett 2021</p>
        </div>
      </div>
    );
  }
}

export default HomePage;
