import React, { Component } from "react";
class HomeTitle extends Component {
  render() {
    return (
      <div>
        <div className="homePageTitleContainer">
          <h1 className="homePageTitleSubText">Off the wall art...</h1>
          <h1 className="homePageTitleSubText">for your wall</h1>
          <h1 className="homePageTitleText">
            Sam Barrett is a London-based abstract artist
          </h1>
        </div>
      </div>
    );
  }
}

export default HomeTitle;
